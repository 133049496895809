import { DotLegalMultiSelect, DotLegalTableWithControls, ITableHeader, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, GridSize, TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import TableWithControlsWrapper from "../../common/components/tableWithControlsWrapper/TableWithControlsWrapper";
import DotLegalModal from "../../common/modal/DotLegalModal";
import ConfigureUser from "../../core/users/ConfigureUser";
import ResendInvitation from "../../core/users/ResendInvitation";
import invitationIcon from "../icons/Invitation.svg";
import { ApplicationUser, ConfigurableApplicationUser, UsersTableModel } from "./User.types";
import UserOverflowMenu from "./UserOverflowMenu";
import { configurableUserIsValid, deleteUser, initialConfigurableUser, mapApplicationUserToConfigurableUser, saveUser } from "./UserService";
import { useUsersTable } from "./UsersTable.hooks";
import useUserTableStyles from "./UserTable.styles";

type UserTableProps = {};

function UserTable(props: UserTableProps) {
    const classes = useUserTableStyles();
    const snackbar = useDotLegalSnackbar();
    const {
        isLoading,
        users,
        organisationFilterOptions,
        userRoleFilterOptions,
        selectedOrganisations,
        selectedUserRoles,
        setSelectedOrganisations,
        setSelectedUserRoles,
        refetchUsers,
    } = useUsersTable();

    const [configurableUser, setConfigurableUser] = useState<ConfigurableApplicationUser>({
        ...initialConfigurableUser,
    });
    const [configureUserModalIsOpen, setConfigureUserModalIsOpen] = useState<boolean>(false);
    const [resendInvitationModalIsOpen, setResendInvitationModalIsOpen] = useState<boolean>(false);

    const [waitingForServer, setWaitingForServer] = useState(false);
    const [deleteUseModelOpen, setDeleteUserModelOpen] = useState<boolean>(false);

    const onInvitationModalClosed = (): void => setResendInvitationModalIsOpen(false);
    const configureUser = (user: ConfigurableApplicationUser = { ...initialConfigurableUser }): void => {
        setConfigurableUser(user);
        setConfigureUserModalIsOpen(true);
    };

    const resendInvitation = (user: ApplicationUser): void => {
        setConfigurableUser(mapApplicationUserToConfigurableUser(user));
        setResendInvitationModalIsOpen(true);
    };

    const saveConfiguredUser = (): void => {
        if (!configurableUserIsValid(configurableUser)) return;
        setWaitingForServer(true);
        saveUser(configurableUser)
            .then(() => {
                snackbar.show("User saved successfully", "success");
                setWaitingForServer(false);
                setConfigureUserModalIsOpen(false);
                refetchUsers();
            })
            .catch((reason) => {
                snackbar.show(reason.message, "error");
                setWaitingForServer(false);
            });
    };

    const configureDeleteUser = (user: ConfigurableApplicationUser = { ...initialConfigurableUser }): void => {
        setConfigurableUser(user);
        setDeleteUserModelOpen(true);
    };
    const deleteConfiguredUser = (): void => {
        if (!configurableUserIsValid(configurableUser)) return;
        setWaitingForServer(true);
        deleteUser(configurableUser.id!)
            .then(() => {
                snackbar.show("User deleted successfully", "success");
                setWaitingForServer(false);
                setDeleteUserModelOpen(false);
                refetchUsers();
            })
            .catch((reason) => {
                snackbar.show(reason.message, "error");
                setWaitingForServer(false);
            });
    };

    const getSearchFields = () => {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 2,
            lg: 2,
        };

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedOrganisations}
                        chosenOptions={selectedOrganisations}
                        label={"Organisation(s)"}
                        placeholder={"Organisation(s)"}
                        isLoading={isLoading}
                        options={organisationFilterOptions}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedUserRoles}
                        chosenOptions={selectedUserRoles}
                        label={"User role(s)"}
                        placeholder={"User role(s)"}
                        isLoading={isLoading}
                        options={userRoleFilterOptions}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <>
            <TableWithControlsWrapper>
                <DotLegalTableWithControls
                    extraControls={getSearchFields()}
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 15}
                    labelRowsPerPage={"Test"}
                    labelSearch={"Search"}
                    noOptionsLabel={"No options"}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    paginationLabelOf={"of"}
                    hideRowsPerPage
                    emptyText={"NO RESULTS FOUND. TRY ADJUSTING YOUR SEARCH CRITERIA."}
                    data={mapUsersToTableModel(users)}
                    renderRow={(r) => (
                        <TableRow
                            key={r.id}
                            onClick={(event) => {
                                configureUser(mapApplicationUserToConfigurableUser(r));
                            }}
                        >
                            <TableCell style={{ paddingLeft: 6 }}>{r.name}</TableCell>
                            <TableCell>{r.email}</TableCell>
                            <TableCell>
                                {r.countryCode} {r.phoneNumber}
                            </TableCell>
                            <TableCell>{r.userRoles.map((role) => role.name).join(", ")}</TableCell>
                            <TableCell>
                                <div className={classes.ellipsisCell}>{r.organizations.map((org) => org.name).join(", ")}</div>
                            </TableCell>
                            <TableCell width={160} align="center">
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        resendInvitation(r);
                                    }}
                                    onKeyPress={() => {
                                        resendInvitation(r);
                                    }}
                                >
                                    <img src={invitationIcon} alt="" />
                                </div>
                            </TableCell>
                            <TableCell align="right">
                                <UserOverflowMenu
                                    user={configurableUser}
                                    openEditModal={() => configureUser(mapApplicationUserToConfigurableUser(r))}
                                    openDeleteModal={() => configureDeleteUser(mapApplicationUserToConfigurableUser(r))}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </TableWithControlsWrapper>
            <DotLegalModal
                open={configureUserModalIsOpen}
                onClose={() => {
                    setConfigureUserModalIsOpen(false);
                    return undefined;
                }}
                title={configurableUser.new ? "Add user" : "Edit user"}
                content={<ConfigureUser user={configurableUser} OnUserChanged={setConfigurableUser} waitingForServer={waitingForServer} />}
                action={
                    configurableUser.new ? (
                        <>
                            <Button
                                color="primary"
                                variant={"outlined"}
                                disabled={!configurableUserIsValid(configurableUser)}
                                onClick={() => saveConfiguredUser()}
                            >
                                Send invite
                            </Button>
                        </>
                    ) : (
                        <Button color="secondary" variant={"outlined"} disabled={!configurableUserIsValid(configurableUser)} onClick={saveConfiguredUser}>
                            Save
                        </Button>
                    )
                }
            />
            <DotLegalModal
                open={deleteUseModelOpen}
                onClose={() => setDeleteUserModelOpen(false)}
                title={`Delete User`}
                content={
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <React.Fragment>
                            <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(14) })}>
                                {"Are you sure you want to delete "} <strong>{configurableUser.name}?</strong>
                            </Typography>
                        </React.Fragment>
                    </div>
                }
                action={
                    <React.Fragment>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "40px",
                            }}
                        >
                            <Button
                                sx={{ marginRight: "30px", borderWidth: "medium", border: "1px solid" }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => setDeleteUserModelOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: "#F7447A" }} onClick={deleteConfiguredUser}>
                                Delete
                            </Button>
                        </Box>
                    </React.Fragment>
                }
            />
            <DotLegalModal
                content={<ResendInvitation invitee={configurableUser} onClose={onInvitationModalClosed} />}
                onClose={onInvitationModalClosed}
                open={resendInvitationModalIsOpen}
                title="Resend invitation"
            />
            <div style={{ paddingTop: 40, display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" onClick={() => configureUser()} variant="outlined">
                    Add user
                </Button>
            </div>
        </>
    );

    function getHeaders(): Array<ITableHeader<UsersTableModel>> {
        return [
            { property: "name", text: "Name", align: "left", showOnMobile: true, width: "300" },
            { property: "email", text: "Email", align: "left", showOnMobile: true, width: "300" },
            { property: "phoneNumber", text: "Phone", align: "left", showOnMobile: true, width: "300" },
            { property: "userRoles", text: "User Role(s)", align: "left", showOnMobile: true, width: "300" },
            { property: "organizations", text: "Organisation(s)", align: "left", showOnMobile: true, notSortable: true, width: "300" },
            { property: "resendInvitation", text: "Resend Invitation(s)", align: "left", showOnMobile: true, notSortable: true, width: "150" },
        ];
    }

    function mapUsersToTableModel(users: Array<ApplicationUser>) {
        return users.map((u) => {
            return { ...u, resendInvitation: true };
        });
    }
}

export default UserTable;
