import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import ContentCard from "../../common/contentCard/ContentCard";
import OrganizationTable from "../../core/organizations/OrganizationTable";
import { useUrlProvider } from "../../useUrlProvider";

type OrganisationsProps = {
    onSelectedOrgCostAccessChanged: () => void;
};

function Organisations(props: OrganisationsProps) {
    const { getAdminUrl } = useUrlProvider();
    useDotLegalBreadCrumbs([{ name: "Admin", link: getAdminUrl() }, { name: "Organisations" }]);

    return (
        <ContentCard>
            <OrganizationTable onSelectedOrgCostAccessChanged={props.onSelectedOrgCostAccessChanged} />
        </ContentCard>
    );
}

export default Organisations;
