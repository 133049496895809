import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization } from "./Organization.types";

export interface IConfigureRenewalPrices {
    organization: Organization;
    users: Array<{ id: number; name: string; organizationsIds: Array<number> }>;
    onOrganizationChanged: (organization: Organization) => void;
}

export default function ConfigureRenewalPrices(props: IConfigureRenewalPrices) {
    const [selectedUsers, setSelectedUsers] = useState<AutocompleteOption<number>[]>(
        props.users
            .filter((option) => props.organization.renewalContacts.some((contact) => contact.userId === option.id))
            .map((x) => {
                return { value: x.id, label: x.name };
            })
    );

    const onRenewalContactsChange = (contacts: AutocompleteOption<number>[]) => {
        setSelectedUsers(contacts);
        props.onOrganizationChanged({
            ...props.organization,
            renewalContacts: contacts.map((x) => {
                return { organizationId: Number(props.organization.id!), userId: x.value! };
            }),
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="multiSelectDropDown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={(values) => onRenewalContactsChange(values)}
                    label="Renewal Contacts"
                    value={selectedUsers}
                    width={"100%"}
                    options={props.users.map((x) => {
                        return { value: x.id, label: x.name };
                    })}
                    tooltip={
                        <>
                            {`Renewal contacts are client contacts who will receive reminders about upcoming renewals. You can select one or more contacts from the dropdown, which includes all users with the "Client" user role who have access to the specific organisation. The renewal contacts will receive the following reminders:`}
                            <Box component={"ul"}>
                                <Box component={"li"}>{`First renewal: September 1st`}</Box>
                                <Box component={"li"}>{`Second renewal: November 1st`}</Box>
                                <Box component={"li"}>{`Monthly reminder for upcoming renewals within the next month.`}</Box>
                                <Box component={"li"}>{`Reminder only for designs March 1st.`}</Box>
                            </Box>
                            {`Renewal reminders will only be sent if:`}
                            <Box component={"ul"}>
                                <Box component={"li"}>{`The client has cases that are due for renewal within the period.`}</Box>
                                <Box component={"li"}>{`The organisation has at least one renewal contact.`}</Box>
                                <Box component={"li"}>{`The renewal status is set to "Show Renewal Status".`}</Box>
                            </Box>
                            {`If a new user in the portal needs to receive reminders, they must be manually added as a renewal contact.`}
                        </>
                    }
                />
            </Box>
        </Box>
    );
}
