import { DotLegalMultiSelect, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, CircularProgress, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { setCostAccessLevelOnSelectedOrg } from "../../auth/AuthService";
import TableWithControlsWrapper from "../../common/components/tableWithControlsWrapper/TableWithControlsWrapper";
import DotLegalModal from "../../common/modal/DotLegalModal";
import DotLegalNavigationIcon from "../../common/navigationIcon/DotLegalNavigationIcon";
import ConfigureOrganization from "./ConfigureOrganization";
import ConfigureOrganizationPriceDisclaimer from "./ConfigureOrganizationPriceDisclaimer";
import ConfigureRenewalPrices from "./ConfigureRenewalContacts";
import ConfigureRenewalPrice from "./ConfigureRenewalPrice";
import ConfigureRenewalRegistrationActive from "./ConfigureRenewalRegistrationActive";
import { Organization, PriceDisclaimerType, RenewalPriceAccess } from "./Organization.types";
import { saveOrganization } from "./OrganizationService";
import { mapClientHasAccess, mapPriceDisclaimer, mapRenewalPriceAccessToString, useOrganizations } from "./OrganizationTable.hooks";

type OrganizationTableProps = {
    onSelectedOrgCostAccessChanged: () => void;
};

function OrganizationTable(props: OrganizationTableProps) {
    const [configureOrganizationModalIsOpen, setConfigureOrganizationModalIsOpen] = useState(false);

    const {
        isLoading,
        users,
        organizations,
        refetchOrganizations,
        selectedCostAccess,
        setSelectedCostAccess,
        selectedRenewalPrice,
        setSelectedRenewalPrice,
        selectedRenewalStatus,
        setSelectedRenewalStatus,
        renewalContactFilterOptions,
        selectedRenewalContacts,
        setSelectedRenewalContacts,
        selectedEmailPriceDisclaimer,
        setSelectedEmailPriceDisclaimer,
        selectedRenewalReminders,
        setSelectedRenewalReminders,
        setSelectedResponsible,
        selectedResponsible,
        responsibleFilterOptions,
        selectedHasClientAccess,
        setSelectedHasClientAccess,
    } = useOrganizations();

    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const [waitingForServer, setWaitingForServer] = useState(false);
    const [availableRenewalContacts, setAvailableRenewalContacts] = useState(Array<{ id: number; name: string; organizationsIds: Array<number> }>);

    const configureOrganization = (organization: Organization): void => {
        setSelectedOrganization(organization);
        setConfigureOrganizationModalIsOpen(true);
        if (organization) {
            const availableUsers = users.filter(
                (y) => y.organizationsIds.some((o) => o === organization.id) || organization.renewalContacts.some((rc) => rc.userId === y.id)
            );
            setAvailableRenewalContacts(availableUsers);
        }
    };

    const saveConfiguredOrganization = (): void => {
        if (!selectedOrganization || !selectedOrganization.costAccess) return;
        setWaitingForServer(true);
        saveOrganization(selectedOrganization)
            .then(() => {
                setWaitingForServer(false);
                setConfigureOrganizationModalIsOpen(false);
                setCostAccessLevelOnSelectedOrg(selectedOrganization, props.onSelectedOrgCostAccessChanged);
                refetchOrganizations();
            })
            .catch(() => setWaitingForServer(false));
    };

    const onConfigureOrganizationModalClosed = (): void => {
        setConfigureOrganizationModalIsOpen(false);
    };

    const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize | boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };
    const getSearchFields = () => {
        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedCostAccess}
                        chosenOptions={selectedCostAccess}
                        label={"Costs"}
                        placeholder={"Costs"}
                        isLoading={isLoading}
                        options={[
                            { id: "Brief overview", name: "Brief overview" },
                            { id: "Detailed overview", name: "Detailed overview" },
                            { id: "Hide costs", name: "Hide costs" },
                        ]}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedRenewalPrice}
                        chosenOptions={selectedRenewalPrice}
                        label={"Renewal price"}
                        placeholder={"Renewal price"}
                        isLoading={isLoading}
                        options={[
                            { id: RenewalPriceAccess.Employee.toString(), name: mapRenewalPriceAccessToString(RenewalPriceAccess.Employee) },
                            { id: RenewalPriceAccess.Hidden.toString(), name: mapRenewalPriceAccessToString(RenewalPriceAccess.Hidden) },
                            { id: RenewalPriceAccess.EmployeeAndClient.toString(), name: mapRenewalPriceAccessToString(RenewalPriceAccess.EmployeeAndClient) },
                        ]}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedRenewalStatus}
                        chosenOptions={selectedRenewalStatus}
                        label={"Renewal status"}
                        placeholder={"Renewal status"}
                        isLoading={isLoading}
                        options={[
                            { id: "true", name: "Show renewal status" },
                            { id: "false", name: "Hide renewal status" },
                        ]}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedRenewalContacts}
                        chosenOptions={selectedRenewalContacts}
                        label={"Renewal contacts(s)"}
                        placeholder={"Renewal contacts(s)"}
                        isLoading={isLoading}
                        options={renewalContactFilterOptions}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedEmailPriceDisclaimer}
                        chosenOptions={selectedEmailPriceDisclaimer}
                        label={"Email price disclaimer"}
                        placeholder={"Email price disclaimer"}
                        isLoading={isLoading}
                        options={[
                            { id: PriceDisclaimerType.FeeIncluded.toString(), name: mapPriceDisclaimer(PriceDisclaimerType.FeeIncluded) },
                            { id: PriceDisclaimerType.FeeExcluded.toString(), name: mapPriceDisclaimer(PriceDisclaimerType.FeeExcluded) },
                            { id: PriceDisclaimerType.NoDisclaimer.toString(), name: mapPriceDisclaimer(PriceDisclaimerType.NoDisclaimer) },
                        ]}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedRenewalReminders}
                        chosenOptions={selectedRenewalReminders}
                        label={"Renewal reminders"}
                        placeholder={"Renewal reminders"}
                        isLoading={isLoading}
                        options={[
                            { id: "true", name: "Yes" },
                            { id: "false", name: "No" },
                        ]}
                        noMargin
                        Limit={1}
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedResponsible}
                        chosenOptions={selectedResponsible}
                        label={"Responsible"}
                        placeholder={"Responsible"}
                        isLoading={isLoading}
                        options={responsibleFilterOptions}
                        noMargin
                        noOptionsLabel={"No options"}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSelectedHasClientAccess}
                        chosenOptions={selectedHasClientAccess}
                        label={"Client access"}
                        placeholder={"Client access"}
                        isLoading={isLoading}
                        Limit={1}
                        options={[
                            { id: "true", name: "Client access" },
                            { id: "false", name: "No client access" },
                        ]}
                        noMargin
                        noOptionsLabel={"No options"}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <>
            <TableWithControlsWrapper>
                <DotLegalTableWithControls
                    searchFieldFullWidth
                    searchFieldGridSizes={tableGridProps}
                    getUserSpecificPageLength={() => 15}
                    extraControls={getSearchFields()}
                    headers={getOrganizationsTableHeader()}
                    labelRowsPerPage={""}
                    labelSearch={"Search"}
                    noOptionsLabel={""}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    paginationLabelOf={"of"}
                    data={organizations}
                    hideRowsPerPage
                    emptyText={"NO RESULTS FOUND. TRY ADJUSTING YOUR SEARCH CRITERIA."}
                    renderRow={(r) => (
                        <TableRow onClick={() => configureOrganization(r)} key={r.id}>
                            <TableCell style={{ paddingLeft: 6 }}>{r.name}</TableCell>
                            <TableCell>{r.managerFirstName ? `${r.managerFirstName} ${r.managerSurName}` : ""}</TableCell>
                            <TableCell>{r.costAccess}</TableCell>
                            <TableCell>{mapRenewalPriceAccessToString(r.showRenewalPrice)}</TableCell>
                            <TableCell>
                                {users
                                    .filter((x) => r.renewalContacts.some((y) => y.userId === x.id))
                                    .map((u) => u.name)
                                    .join(", ")}
                            </TableCell>
                            <TableCell>{r.renewalRegistrationActive ? "Show renewal status" : "Hide renewal status"}</TableCell>
                            <TableCell>{mapPriceDisclaimer(r.priceDisclaimer)}</TableCell>
                            <TableCell>{mapClientHasAccess(r.hasClientAccess)}</TableCell>
                            <TableCell align="right">
                                <DotLegalNavigationIcon />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </TableWithControlsWrapper>
            <DotLegalModal
                action={
                    <Button color="secondary" variant={"outlined"} onClick={saveConfiguredOrganization}>
                        Save
                    </Button>
                }
                content={
                    selectedOrganization ? (
                        <Box sx={{ paddingTop: "10px" }}>
                            <ConfigureOrganization onOrganizationChanged={setSelectedOrganization} organization={selectedOrganization} />
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalPrice onOrganizationChanged={setSelectedOrganization} organization={selectedOrganization} />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalRegistrationActive onOrganizationChanged={setSelectedOrganization} organization={selectedOrganization} />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalPrices
                                    onOrganizationChanged={setSelectedOrganization}
                                    organization={selectedOrganization}
                                    users={availableRenewalContacts}
                                />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureOrganizationPriceDisclaimer organization={selectedOrganization} onOrganizationChanged={setSelectedOrganization} />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                {waitingForServer && <CircularProgress />}
                            </Box>
                        </Box>
                    ) : (
                        <div>Something went wrong</div>
                    )
                }
                onClose={onConfigureOrganizationModalClosed}
                open={configureOrganizationModalIsOpen}
                title="Settings"
                subTitle={selectedOrganization?.name}
            />
        </>
    );

    function getOrganizationsTableHeader(): Array<ITableHeader<Organization>> {
        return [
            { text: "Name", property: "name", align: "left", width: "300" },
            { text: "Responsible", property: "managerFirstName", align: "left" },
            {
                text: "Costs",
                property: "costAccess",
                align: "left",
                width: "200",
                notSortable: true,
            },
            { text: "Renewal Price", property: "showRenewalPrice", align: "left" },
            { text: "Renewal Contact(s)", property: "renewalContacts", align: "left" },
            { text: "Renewal Status", property: "renewalRegistrationActive", align: "left" },
            { text: "Email Price Disclaimer", property: "priceDisclaimer", align: "left" },
            { text: "Client Access", property: "hasClientAccess", align: "left" },
        ];
    }
}

export default OrganizationTable;
