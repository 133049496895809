import { DotLegalSelectOption } from "@dotlegal/dotlegal-ui-components/dist/cjs/types/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { exhaustiveGuard } from "../../common/utilities";
import { Organization, OrganizationsResponse, PriceDisclaimerType, RenewalPriceAccess } from "./Organization.types";

export function useOrganizations() {
    const [selectedCostAccess, setSelectedCostAccess] = useState<Array<string>>([]);
    const [selectedRenewalPrice, setSelectedRenewalPrice] = useState<Array<string>>([]);
    const [selectedRenewalStatus, setSelectedRenewalStatus] = useState<Array<string>>([]);
    const [selectedRenewalContacts, setSelectedRenewalContacts] = useState<Array<string>>([]);
    const [selectedEmailPriceDisclaimer, setSelectedEmailPriceDisclaimer] = useState<Array<string>>([]);
    const [selectedRenewalReminders, setSelectedRenewalReminders] = useState<Array<string>>([]);
    const [selectedResponsible, setSelectedResponsible] = useState<Array<string>>([]);
    const [selectedHasClientAccess, setSelectedHasClientAccess] = useState<Array<string>>([]);

    const usersQuery = useQuery("allUsers", () => get<Array<{ id: number; name: string; organizationsIds: Array<number> }>>("/api/users/all"));
    const { isLoading, data, refetch } = useQuery("organizations", () => get<OrganizationsResponse>("/api/organizations"));

    let responsibleFilterOptions: Array<DotLegalSelectOption> = [];
    let renewalContactFilterOptions: Array<DotLegalSelectOption> = [];
    if (data && usersQuery.data) {
        createFilterOptions(data.organizations);
    }

    const refetchOrganizations = () => {
        refetch();
    };

    return {
        isLoading: isLoading || usersQuery.isLoading,
        organizations: getFilteredRows(data?.organizations ?? []),
        users: usersQuery.data ?? [],
        refetchOrganizations,
        selectedCostAccess,
        setSelectedCostAccess,
        selectedRenewalPrice,
        setSelectedRenewalPrice,
        selectedRenewalStatus,
        setSelectedRenewalStatus,
        renewalContactFilterOptions,
        selectedRenewalContacts,
        setSelectedRenewalContacts,
        selectedEmailPriceDisclaimer,
        setSelectedEmailPriceDisclaimer,
        selectedRenewalReminders,
        setSelectedRenewalReminders,
        setSelectedResponsible,
        selectedResponsible,
        responsibleFilterOptions,
        selectedHasClientAccess,
        setSelectedHasClientAccess,
    };

    function createFilterOptions(organisations: Array<Organization>) {
        organisations.forEach((org) => {
            org.renewalContacts.forEach((contact) => {
                if (!renewalContactFilterOptions.some((x) => x.id === contact.userId.toString())) {
                    let user = (usersQuery.data ?? []).find((x) => x.id == contact.userId);
                    renewalContactFilterOptions.push({ id: contact.userId.toString(), name: user ? user.name : "" });
                }
            });

            if (org.managerFirstName && org.managerInitials) {
                const responsible = `${org.managerFirstName} ${org.managerSurName}`;
                if (!responsibleFilterOptions.some((x) => x.id === org.managerInitials)) {
                    responsibleFilterOptions.push({ id: org.managerInitials, name: responsible });
                }
            }
        });
    }

    function getFilteredRows(users: Array<Organization>) {
        let tempData = [...users];

        if (selectedCostAccess.length > 0 && selectedCostAccess[0] !== "") {
            tempData = tempData.filter((row) => selectedCostAccess.indexOf(row.costAccess?.toString() ?? "") !== -1);
        }

        if (selectedRenewalPrice.length > 0 && selectedRenewalPrice[0] !== "") {
            tempData = tempData.filter((row) => selectedRenewalPrice.indexOf(row.showRenewalPrice.toString()) !== -1);
        }

        if (selectedRenewalStatus.length > 0 && selectedRenewalStatus[0] !== "") {
            tempData = tempData.filter((row) => selectedRenewalStatus.indexOf(row.renewalRegistrationActive.toString()) !== -1);
        }

        if (selectedEmailPriceDisclaimer.length > 0 && selectedEmailPriceDisclaimer[0] !== "") {
            tempData = tempData.filter((row) => selectedEmailPriceDisclaimer.indexOf(row.priceDisclaimer.toString()) !== -1);
        }

        if (selectedRenewalContacts.length > 0 && selectedRenewalContacts[0] !== "") {
            tempData = tempData.filter(
                (row) => row.renewalContacts.filter((contact) => selectedRenewalContacts.indexOf(contact.userId.toString()) >= 0).length > 0
            );
        }

        if (selectedRenewalReminders.length > 0 && selectedRenewalReminders[0] !== "") {
            tempData = tempData.filter(
                (row) =>
                    (selectedRenewalReminders.indexOf("true") !== -1 && row.renewalContacts.length > 0 && row.renewalRegistrationActive) ||
                    (selectedRenewalReminders.indexOf("false") !== -1 && (row.renewalContacts.length === 0 || !row.renewalRegistrationActive))
            );
        }

        if (selectedResponsible.length > 0 && selectedResponsible[0] !== "") {
            tempData = tempData.filter((row) => selectedResponsible.indexOf(row.managerInitials ?? "") !== -1);
        }

        if (selectedHasClientAccess.length > 0 && selectedHasClientAccess[0] !== "") {
            tempData = tempData.filter(
                (row) =>
                    (selectedHasClientAccess.indexOf("true") !== -1 && row.hasClientAccess) ||
                    (selectedHasClientAccess.indexOf("false") !== -1 && !row.hasClientAccess)
            );
        }

        return tempData;
    }
}

export function mapPriceDisclaimer(priceDisclaimer: PriceDisclaimerType) {
    switch (priceDisclaimer) {
        case PriceDisclaimerType.FeeIncluded:
            return "Bech-Bruun fee included";
        case PriceDisclaimerType.FeeExcluded:
            return "Bech-Bruun fee excluded";
        case PriceDisclaimerType.NoDisclaimer:
            return "No disclaimer";
        default:
            exhaustiveGuard(priceDisclaimer);
    }
}

export function mapClientHasAccess(hasClientAccess: boolean) {
    return hasClientAccess ? "Client access" : "No client access";
}

export function mapRenewalPriceAccessToString(renewalPriceAccess: RenewalPriceAccess) {
    switch (renewalPriceAccess) {
        case RenewalPriceAccess.Employee:
            return "Employee";
        case RenewalPriceAccess.Hidden:
            return "Hidden";
        case RenewalPriceAccess.EmployeeAndClient:
            return "Employee and client";
        default:
            exhaustiveGuard(renewalPriceAccess);
    }
}
