import { Box, Tooltip } from "@mui/material";

export interface DotLegalInformationTooltipProps {
    text: React.ReactNode;
    small?: boolean;
}

function DotLegalInformationTooltip(props: DotLegalInformationTooltipProps) {
    return (
        <Tooltip
            title={props.text}
            componentsProps={{
                tooltip: {
                    sx: {
                        maxHeight: "unset",
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    "& svg": {
                        width: props.small ? 14 : 18,
                        height: props.small ? 14 : 18,
                    },
                }}
            >
                {informationIcon}
            </Box>
        </Tooltip>
    );
}

const informationIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14">
        <g id="Group_2791" data-name="Group 2791" transform="translate(-1019 -242)">
            <g id="Ellipse_65" data-name="Ellipse 65" transform="translate(1019 242)" fill="#efeffa" stroke="#7284fa" strokeWidth="1">
                <circle cx="7" cy="7" r="7" stroke="none" />
                <circle cx="7" cy="7" r="6.5" fill="none" />
            </g>
            <g id="info" transform="translate(993.179 245)">
                <path
                    id="Path_1500"
                    data-name="Path 1500"
                    d="M34.4,6.8l-.106.435q-.479.189-.764.288a2.016,2.016,0,0,1-.663.1,1.323,1.323,0,0,1-.9-.283.915.915,0,0,1-.322-.719,2.542,2.542,0,0,1,.024-.346q.024-.177.078-.4l.4-1.413q.053-.2.09-.385a1.672,1.672,0,0,0,.036-.331.512.512,0,0,0-.112-.378.631.631,0,0,0-.427-.107,1.119,1.119,0,0,0-.317.048c-.109.032-.2.063-.279.092l.107-.435q.392-.16.75-.273a2.244,2.244,0,0,1,.679-.114,1.291,1.291,0,0,1,.888.278.924.924,0,0,1,.312.723c0,.061-.007.17-.022.324a2.168,2.168,0,0,1-.08.426l-.4,1.407a3.737,3.737,0,0,0-.087.388,1.994,1.994,0,0,0-.039.329.474.474,0,0,0,.125.382.707.707,0,0,0,.435.1,1.255,1.255,0,0,0,.329-.051A1.838,1.838,0,0,0,34.4,6.8Zm.1-5.907a.823.823,0,0,1-.278.627.946.946,0,0,1-.669.259.957.957,0,0,1-.673-.259A.821.821,0,0,1,32.6.89a.83.83,0,0,1,.281-.629.993.993,0,0,1,1.342,0A.831.831,0,0,1,34.505.89Z"
                    transform="translate(0 0)"
                    fill="#7284fa"
                />
            </g>
        </g>
    </svg>
);

export default DotLegalInformationTooltip;
