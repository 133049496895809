import { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ajaxQueue } from "../../../../../common/api/ajaxQuery";
import { put } from "../../../../../common/api/apiShared";
import { PeriodsSaveModel } from "./designFees.types";
import { RenewalFeesProps } from "./RenewalFees";

export function useRenewalFees(props: RenewalFeesProps) {
    const queryClient = useQueryClient();
    const numberOfDesingsref = useRef<string | null>(props.data?.singleFilingFees?.periods ? props.data?.singleFilingFees?.periods.length.toString() : null);

    const changePeriodsCountMutation = useMutation(changeNumberOfPeriodsAPI, {
        onSuccess: (data) => {
            queryClient.setQueryData(props.data?.currentSurveyId!, data.value());
        },
    });
    function changeNumberOfPeriodsAPI(data: PeriodsSaveModel) {
        return put<{}>(`/api/survey/design/periods`, data);
    }

    const onPeriodChange = async (numberOfPeriods: string | null) => {
        numberOfDesingsref.current = numberOfPeriods;
        ajaxQueue(props.data?.currentSurveyId).addRequest(changePeriodsCountMutation.mutateAsync, {
            surveyId: props.data?.currentSurveyId!,
            numberOfPeriods: numberOfPeriods ? Number(numberOfPeriods) : null,
        });
    };

    function formatPeriod(number: number) {
        if (number === 1) {
            return `${number} renewal period`;
        }

        return `${number} renewal periods`;
    }

    function getPeriods() {
        let result: any[] = [];

        for (let i = 1; i <= 20; i++) {
            result.push({ id: i.toString(), name: formatPeriod(i) });
        }

        return result;
    }

    return {
        isChangingNumberOfPeriods: changePeriodsCountMutation.isLoading,
        numberOfDesingsref,
        onPeriodChange,
        getPeriods,
    };
}
