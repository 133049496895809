import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization, RenewalPriceAccess } from "./Organization.types";
import { mapRenewalPriceAccessToString } from "./OrganizationTable.hooks";

export interface IConfigureRenewalPrices {
    organization: Organization;
    onOrganizationChanged: (organization: Organization) => void;
}

export default function ConfigureRenewalPrice(props: IConfigureRenewalPrices) {
    const priceAccessOptions: AutocompleteOption<RenewalPriceAccess>[] = [];
    for (const renewalAccess in RenewalPriceAccess) {
        // Filter out the enum's numeric keys to avoid calling the function multiple times for each enum value
        if (isNaN(Number(renewalAccess))) {
            if (!priceAccessOptions.some((x) => x.value?.toString() === renewalAccess)) {
                const renewalAccessEnum = RenewalPriceAccess[renewalAccess as keyof typeof RenewalPriceAccess];
                priceAccessOptions.push({ label: mapRenewalPriceAccessToString(renewalAccessEnum), value: renewalAccessEnum });
            }
        }
    }

    const [selectedRenewalPrice, setSelectedRenewalPrice] = useState<AutocompleteOption<RenewalPriceAccess>>(
        priceAccessOptions.find((accessLevel) => accessLevel.value === props.organization.showRenewalPrice)!
    );

    const onRenewalPriceChange = (newValue: AutocompleteOption<RenewalPriceAccess>[]) => {
        setSelectedRenewalPrice(newValue[0] ?? { label: "Hidden", value: 0 });
        props.onOrganizationChanged({
            ...props.organization,
            showRenewalPrice: newValue[0]?.value ?? 0,
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="singleSelectDropdown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={onRenewalPriceChange}
                    label="Renewal price"
                    options={priceAccessOptions}
                    singleSelect
                    // @ts-ignore
                    value={selectedRenewalPrice}
                    width={"100%"}
                    tooltip={
                        <>
                            {
                                'Renewal price refers to the "Estimated Renewal Price" column under the menu item “Renewals". By default, this column is hidden from all users.'
                            }
                            <br />
                            <br />
                            {
                                'If you select "Employee" in the dropdown, the column will be visible only to users with the employee user role and remain hidden from clients. This is useful when employees need to enter price estimates that are not yet ready to be shown to clients. '
                            }
                            <br />
                            <br />
                            {
                                'Once the prices are ready for client visibility, select "Employee and client" to make the column accessible to both. Note that only employees can enter prices, while clients can view them but not edit. '
                            }
                        </>
                    }
                />
            </Box>
        </Box>
    );
}
