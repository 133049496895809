export type Organization = {
    id: number | string;
    name: string;
    costAccess: CostAccessLevel | null;
    showRenewalPrice: RenewalPriceAccess;
    renewalContacts: Array<OrganizationRenewalContact>;
    renewalRegistrationActive: boolean;
    priceDisclaimer: PriceDisclaimerType;
    managerFirstName: string | null;
    managerSurName: string | null;
    managerInitials: string | null;
    hasClientAccess: boolean;
};

export type CostAccessLevel = "Brief overview" | "Detailed overview" | "Hide costs";

export type SelectedOrganization = Organization & {
    hasTrademarks: boolean;
    hasDesigns: boolean;
    hasTrademarkDisputes: boolean;
    hasDesignDisputes: boolean;
    hasCopyrights: boolean;
    hasShowRenewalPriceRight: boolean;
    hasEditRenewalPriceRight: boolean;
    renewalContacts: Array<OrganizationRenewalContact>;
    renewalRegistrationActive: boolean;
    priceDisclaimer: PriceDisclaimerType;
};

export type OrganizationFilter = {
    searchText?: string;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderByDescending: boolean;
};

export type OrganizationRenewalContact = {
    organizationId: number;
    userId: number;
};

export type OrganizationsResponse = {
    organizations: Organization[];
    totalCount: number;
};

export type SaveOrganizationsResponse = {
    success: boolean;
    message: string;
    result: string;
};

export enum PriceDisclaimerType {
    FeeIncluded,
    FeeExcluded,
    NoDisclaimer,
}

export enum RenewalPriceAccess {
    Hidden,
    Employee,
    EmployeeAndClient,
}
