import { DotLegalSelectOption } from "@dotlegal/dotlegal-ui-components/dist/cjs/types/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { Organization } from "../organizations/Organization.types";
import { ApplicationUser, UserRole, UsersResponse } from "./User.types";

export function useUsersTable() {
    const [selectedOrganisations, setSelectedOrganisations] = useState<Array<string>>([]);
    const [selectedUserRoles, setSelectedUserRoles] = useState<Array<string>>([]);

    const { isLoading, data, refetch } = useQuery("admin/users", () => get<UsersResponse>("/api/users"));

    let organisationFilterOptions: Array<DotLegalSelectOption> = [];
    let userRoleFilterOptions: Array<DotLegalSelectOption> = [];
    if (data) {
        createOrganisationSelectOptions(data.users.flatMap((u) => u.organizations));
        createUserRoleSelectOptions(data.users.flatMap((u) => u.userRoles));
    }

    const refetchUsers = () => {
        refetch();
    };

    return {
        isLoading,
        users: getFilteredRows(data?.users ?? []),
        usersCount: data?.totalCount ?? 0,
        organisationFilterOptions: organisationFilterOptions.sort((a, b) => a.name.localeCompare(b.name)),
        userRoleFilterOptions: userRoleFilterOptions.sort((a, b) => a.name.localeCompare(b.name)),
        selectedOrganisations,
        selectedUserRoles,
        setSelectedOrganisations,
        setSelectedUserRoles,
        refetchUsers,
    };

    function createOrganisationSelectOptions(organisations: Array<Organization>) {
        organisations.forEach((org) => {
            if (!organisationFilterOptions.some((x) => x.id === org.id.toString())) {
                organisationFilterOptions.push({ id: org.id.toString(), name: org.name });
            }
        });
    }

    function createUserRoleSelectOptions(userRoles: Array<UserRole>) {
        userRoles.forEach((role) => {
            if (!userRoleFilterOptions.some((x) => x.id === role.id.toString())) {
                userRoleFilterOptions.push({ id: role.id.toString(), name: role.name });
            }
        });
    }

    function getFilteredRows(users: Array<ApplicationUser>) {
        let tempData = [...users];

        if (selectedOrganisations.length > 0 && selectedOrganisations[0] !== "") {
            tempData = tempData.filter((row) => row.organizations.filter((org) => selectedOrganisations.indexOf(org.id.toString()) >= 0).length > 0);
        }

        if (selectedUserRoles.length > 0 && selectedUserRoles[0] !== "") {
            tempData = tempData.filter((row) => row.userRoles.filter((role) => selectedUserRoles.indexOf(role.id.toString()) >= 0).length > 0);
        }

        return tempData;
    }
}
