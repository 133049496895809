import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import ContentCard from "../../common/contentCard/ContentCard";
import UserTable from "../../core/users/UserTable";
import { useUrlProvider } from "../../useUrlProvider";

function Users() {
    const { getAdminUrl } = useUrlProvider();
    useDotLegalBreadCrumbs([{ name: "Admin", link: getAdminUrl() }, { name: "Users" }]);

    return (
        <ContentCard>
            <UserTable />
        </ContentCard>
    );
}

export default Users;
