import { deleteHttp } from "../../common/api/apiShared";
import { Organization } from "../organizations/Organization.types";
import { ApplicationUser, ConfigurableApplicationUser, UserRole } from "./User.types";

export const getAllowedOrganizations = async (): Promise<Organization[]> =>
    fetch("/api/users/me/allowed-organizations", {
        method: "get",
    })
        .then((allowedOrganizationsResponse) => allowedOrganizationsResponse.json())
        .then((allowedOrganizations: Organization[]) => allowedOrganizations || [])
        .catch(() => []);

export const saveUser = async (modifiedUser: ApplicationUser): Promise<void> =>
    fetch("/api/users", {
        method: "put",
        body: JSON.stringify(modifiedUser),
        headers: {
            "Content-Type": "application/json",
        },
    })
        .catch((response) => {
            if (response.statusCode === 500) return response.response.json();
            return response;
        })
        .then((response) => {
            if (response.status === 500) throw new Error(response.detail);
        });

export const resendInvitationMail = (userToSendTo: ApplicationUser): Promise<void> =>
    fetch(`/api/users/${userToSendTo.email}/resend-invitation-mail`, {
        method: "post",
    }).then(() => {});

export const deleteUser = (userId: Number) =>
    deleteHttp(`/api/users/${userId}`)
        .catch((response) => {
            if (response.statusCode === 500) return response.response.json();
            return response;
        })
        .then((response) => {
            if (response.status === 500) throw new Error(response.detail);
        });
export const mapApplicationUserToConfigurableUser = (user: ApplicationUser): ConfigurableApplicationUser => ({
    name: user.name,
    email: user.email,
    phoneNumber: user.phoneNumber,
    countryCode: user.countryCode,
    userRoles: user.userRoles,
    organizations: user.organizations,
    new: false,
    id: user.id,
});

export const configurableUserIsValid = (user: ConfigurableApplicationUser) => {
    const userKeys = (Object.keys(user) as [keyof ConfigurableApplicationUser]).filter((k) => k !== "organizations");

    return !userKeys.some((key) => (!user[key] || user[key] instanceof Array ? (user[key] as any[]).length === 0 : false));
};

export const userHasOrganizationAccess = (user: ApplicationUser | null) => (user ? user.organizations.length >= 1 : false);

export const userHasAccessToMultipleOrganizations = (user: ApplicationUser | null) => (user ? user.organizations.length > 1 : false);

export const userIsAdmin = (user: ApplicationUser | null) => (user ? user.userRoles.some((r) => r.name === "Admin") : false);

export const initialConfigurableUser: ConfigurableApplicationUser = {
    name: "",
    email: "",
    phoneNumber: "",
    userRoles: [],
    organizations: [],
    countryCode: "",
    new: true,
};

export const getUserRoles: UserRole[] = [
    { name: "Employee", id: 0 },
    { name: "Client", id: 1 },
    { name: "Admin", id: 2 },
];
