import { Box } from "@mui/system";

export interface TableWithControlsWrapperProps {
    children: React.ReactNode;
}

function TableWithControlsWrapper(props: TableWithControlsWrapperProps) {
    return (
        <Box
            sx={(theme) => ({
                gap: "24px",
                display: "flex",
                flexDirection: "column",
                borderColor: theme.palette.primary.dark,
                textAlign: "center",
                "& .MuiGrid-item:last-child": {
                    "& .MuiBox-root": {
                        backgroundColor: "white",
                        border: `1px solid ${theme.palette.primary.dark}`,
                    },
                    "& svg": {
                        color: "rgba(0, 0, 0, 0.54)",
                        width: "1em",
                        height: "1em",
                    },
                },
                "& .MuiChip-root": {
                    outline: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
            })}
        >
            {props.children}
        </Box>
    );
}

export default TableWithControlsWrapper;
