import { orgHeader, setSelectedOrgInLocalStorage } from "../../auth/AuthService";
import { CostAccessLevel, Organization, SelectedOrganization } from "./Organization.types";

const organizationsEndpointUri = "/api/organizations";

export const getOrganizations = async (): Promise<Organization[]> => {
    const organizationsResponse = await fetch(`${organizationsEndpointUri}/all`);
    return organizationsResponse.json().then((organizations: Organization[]) => organizations || []);
};

export const saveOrganization = async (modifiedOrganization: Organization): Promise<void> =>
    fetch(`${organizationsEndpointUri}`, {
        method: "put",
        body: JSON.stringify({
            id: modifiedOrganization.id,
            costAccess: GetCostAccessEnumValue(modifiedOrganization.costAccess ?? "Brief overview"),
            showRenewalPrice: modifiedOrganization.showRenewalPrice,
            userids: modifiedOrganization.renewalContacts.map((x) => x.userId),
            renewalRegistrationActive: modifiedOrganization.renewalRegistrationActive,
            priceDisclaimer: modifiedOrganization.priceDisclaimer,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    }).then(() => {});

export const verifySelectedOrganization = async (orgId: number | string): Promise<SelectedOrganization | null> => {
    return fetch(`${organizationsEndpointUri}/verify-selected-organization`, {
        method: "post",
        headers: new Headers(orgHeader(orgId.toString())),
    })
        .then((res) => res.json())
        .then((selectedOrg: SelectedOrganization) => {
            setSelectedOrgInLocalStorage(selectedOrg);
            return selectedOrg;
        });
};

export const orgHasCases = (selectedOrg: SelectedOrganization | null): boolean =>
    !!selectedOrg && (selectedOrg.hasTrademarks || selectedOrg.hasDesigns || selectedOrg.hasCopyrights);

export const userHasCostAccess = (selectedOrg: SelectedOrganization | null): boolean => !!selectedOrg && selectedOrg.costAccess !== "Hide costs";

const GetCostAccessEnumValue = (costAccessLevel: CostAccessLevel) => {
    switch (costAccessLevel) {
        case "Brief overview":
            return 1;
        case "Detailed overview":
            return 2;
        case "Hide costs":
            return 0;
        default:
            return 1;
    }
};
